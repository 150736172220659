import React, { useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import ErrorBoundary from './components/ErrorBoundary';
import SEO from './components/SEO';
import { ResponsiveWrapper } from './components/ResponsiveWrapper';
import { MantineProvider } from '@mantine/core';
import BetaRequest from './pages/BetaRequest';
import { Provider } from 'react-redux';
import store from './redux/store';
import SplashPage from './pages/SplashPage';
import GetAIDeck from './pages/GetAIDeck';
import useDebouncedResize from './components/ScreenResized';



const routeConfig = {
  SplashPage: { component: SplashPage, title: 'Introduction', description: 'Learn about Teraace', url: '/intro' },
  BetaRequest: { component: BetaRequest, title: 'Request Beta Access', description: 'Ask for Teraace Beta Access', url: '/betarequest' },
  // GetAIDeck: { component: GetAIDeck, title: 'Request AI Deck', description: 'Building Product Without Knowing Code: The Power of AI', url: '/getaideck' },
};

const Content = React.memo(() => {
  // console.log("Content component rerendered");
  // const [currentPage, setCurrentPage] = useState('happyPath'); // Example page state
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(location.pathname.replace('/', ''));
  const { title, description, url } = routeConfig[currentPage] || {};
  const pages = Object.keys(routeConfig).map(key => ({
    name: routeConfig[key].title,
    route: routeConfig[key].url,
  }));

  // console.log('App component rendering'); // Add this log  

  
  const onNavigate = (route) => {
    // console.log('onNavigate called');
    // console.log('onNavigate called with route:', route);
    // console.log(`Old Background image set to: ${backgroundImage}`);
    setCurrentPage(route);
    // console.log('Current page after:', currentPage);
    // setBackgroundImage(getRandomBackgroundImage()); // Update background image on navigation
    // console.log(`New Background image set to: ${backgroundImage}`);
  };

  const navigate = useNavigate();

  
  useEffect(() => {
    return () => {
      console.log('App component unmounted');
    };
  }, []);


return (
    <div className="relative min-h-screen scroll">
      <div className="outerwrapper">
    <div className="flex-container">
        <div className="innerwrapper">
            <>
                <ResponsiveWrapper>
                <Sidebar className="sidebar" pages={pages} onNavigate={onNavigate}/>
                    <SEO title={title} description={description} url={url} />
                    <Routes>
                        <Route path="/intro" element={<SplashPage />} />
                        <Route path="/betarequest" element={<BetaRequest />} />                       
                        <Route path="*" element={<Navigate to="/intro" />} />
                        <Route path="/getaideck" element={<GetAIDeck />} />
                    </Routes>
                </ResponsiveWrapper>
            </>
        </div>
      </div>          
      </div> 
    </div>
  );
 });

const App = () => {
  const { width } = useDebouncedResize(300); // Adjust the delay as needed
  const [resizeKey, setResizeKey] = useState(0);

  useEffect(() => {
    // Force a re-render by updating the key
    setResizeKey(prevKey => prevKey + 1);
  }, [width]);

  return (
    <ErrorBoundary>
    <Provider store={store}>
    <MantineProvider theme={{ /* your theme settings */ }}>
    <Router>
    <Content resizeKey={resizeKey} />
        {/* <Routes>
              <Route path="*" element={<Content resizeKey={resizeKey} />} />
            </Routes> */}
        </Router>
    </MantineProvider>
    </Provider>
    </ErrorBoundary>
  );
};

export default App;
